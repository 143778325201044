<!-- 结算列表 -->
<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class="payout-container common-padding"
  >
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <!-- 筛选条件 -->
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>订单编号:</span>
        <el-input
          v-model="params.disOrderCode"
          placeholder="请输入内容"
          class="common-screen-input"
          clearable
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>结算状态:</span>
        <el-select
          v-model="params.settleFlag"
          placeholder="请选择"
          class="common-screen-input"
          @change="commonFun"
          clearable
        >
          <el-option
            v-for="item in options3"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>出账方式:</span>
        <el-select
          v-model="params.settleWay"
          placeholder="请选择"
          class="common-screen-input"
          @change="commonFun"
          clearable
        >
          <el-option
            v-for="item in options2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>收款人:</span>
        <el-input
          v-model="params.collectionName"
          placeholder="请输入内容"
          class="common-screen-input"
          clearable
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>创建人:</span>
        <el-input
          v-model="params.createName"
          placeholder="请输入内容"
          class="common-screen-input"
          clearable
        ></el-input>
      </div>
      <div class="common-input-container">
        <common-date
          @commonGetDate="commonGetDate"
          ref="commonReset"
        ></common-date>
      </div>
      <el-button
        class="common-screen-btn mar"
        type="primary"
        @click="
          () => {
            commonFun();
          }
        "
        >查 询</el-button
      >
      <el-button class="common-screen-btn" plain @click="() => resetBtn()"
        >重 置</el-button
      >
    </div>
    <!-- biaoge -->
    <el-table class="common-table" :data="tableData">
      <el-table-column
        prop="disOrderCode"
        label="订单编号"
        :formatter="tableColumn"
        width="140"
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.settleFlag == 4"
            type="text"
            size="small"
            class="del"
            @click="() => seeMore(scope.row.disOrderNo)"
            >{{ scope.row.disOrderCode }}</el-button
          >
          <el-button
            v-else
            type="text"
            size="small"
            @click="() => seeMore(scope.row.disOrderNo)"
            >{{ scope.row.disOrderCode }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="settleFlagName"
        label="结算状态"
        :formatter="tableColumn"
      >
      <template slot-scope="scope">
          <span
            :class="scope.row.settleFlag == 1 ? 'deleteflag1' : (scope.row.settleFlag == 2 ? 'deleteflag3':scope.row.settleFlag == 3 ? 'deleteflag2':'deleteflag4')"
            >{{ scope.row.settleFlagName }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        label="出账类型"
        :formatter="tableColumn"
      >
      供应商结算
      </el-table-column>
      <el-table-column
        prop="settleWayName"
        label="出账方式"
        :formatter="tableColumn"
        width="90px"
      >
      </el-table-column>
      <el-table-column prop="collectionName" :show-overflow-tooltip="true" label="收款人"> </el-table-column>
      <el-table-column
        prop="phone"
        label="联系方式"
        :formatter="tableColumn"
        :show-overflow-tooltip="true"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="collectionAccount"
        label="收款账户"
        :formatter="tableColumn"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="settleProportion"
        label="结算比例"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="settlePrice"
        label="结算金额"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="paySettlePrice"
        label="已结算金额"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="unPaySettlePrice"
        label="未结算金额"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="createName"
        label="创建人"
        :formatter="tableColumn"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        :formatter="tableColumn"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        :show-overflow-tooltip="true"
        :formatter="tableColumn"
      ></el-table-column>
    </el-table>

    <!-- 新增弹框 -->
    <el-dialog
      v-dialogDrag
      title="新增出账"
      :visible.sync="newAddVisible"
      width="520px"
      :append-to-body="true"
      :before-close="
        () => {
          close();
        }
      "
    >
      <el-form
        ref="form"
        :rules="rulesIncome"
        :model="seeMoreForm"
        label-position="left"
        label-width="100px"
      >
        <el-form-item label="出账金额" prop="amount">
          <el-input v-model.trim="seeMoreForm.amount" class="ipt2"></el-input>元
        </el-form-item>
        <el-form-item label="出账类型" prop="expenditureType">
          <el-select
            v-model="seeMoreForm.expenditureType"
            placeholder="请选择"
            class="ipt"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出账方式" prop="recordType">
          <el-select
            v-model="seeMoreForm.recordType"
            placeholder="请选择"
            class="ipt"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收款人" prop="transactionName">
          <el-input
            v-model.trim="seeMoreForm.transactionName"
            class="ipt"
          ></el-input>
        </el-form-item>
        <el-form-item label="收款账户" prop="transactionAccount">
          <el-input
            v-model.trim="seeMoreForm.transactionAccount"
            class="ipt"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="transactionPhone">
          <el-input
            v-model.trim="seeMoreForm.transactionPhone"
            class="ipt"
          ></el-input>
        </el-form-item>
        <el-form-item label="出款时间" prop="recordTimeString">
          <el-date-picker
            v-model="seeMoreForm.recordTimeString"
            type="date"
            placeholder="选择日期"
            class="ipt"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="通知人" prop="noticeId">
          <el-select
            v-if="noticeList && noticeList.length"
            class="ipt"
            v-model="seeMoreForm.noticeId"
            placeholder="请选择通知人"
            multiple
          >
            <el-option
              v-for="item in noticeList"
              :key="item.id"
              :label="item.nickName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="seeMoreForm.remark"
            type="textarea"
            :rows="3"
            class="ipt"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="orderFile-container">
            <div class="orderFileBtn">
              上传附件
              <input
                class="orderFile-input"
                type="file"
                @input="
                  (e) => {
                    orderFile(e);
                  }
                "
              />
            </div>
            上传文件及图片，大小不超过50MB
          </div>
          <div class="fileList-container">
            <div
              v-for="(item, index) in fileList"
              :key="index"
              class="fileList-item"
            >
              <div class="item-name">
                <common-picture
                  :fileUrl="item.fileUrl"
                  :fileType="
                    Config.fileType.imgType.includes(
                      item.fileUrl.split('.')[
                        item.fileUrl.split('.').length - 1
                      ]
                    )
                      ? 'imgText'
                      : 'file'
                  "
                  :fileName="item.fileName"
                ></common-picture>
              </div>
              <div
                @click="
                  () => {
                    deleteFlie(index);
                  }
                "
                class="deleteBtn"
              >
                删除
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="newAddVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              sendRequest();
            }
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.page"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>

    <settle-detail
      ref="settleDetail"
      :drawer="dialogVisible"
      :tableRow="tableRow"
      :loading="loading"
      :customerInfo="customerInfo"
      :adminInfo="adminInfo"
      :chanceFollowList="chanceFollowList"
      :childid="childid"
      @handleClose="handleClose"
      @getpayout="seeMore"
      @loadFun="loadFun"
      @payoutDetail="closeDialog"
      @getpaylist="getSettleList"
    ></settle-detail>

  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { tableColumn, Config, rulePrice } from "../../utils/index.js";
import settleDetail from "./children/settleDetail.vue";
import CommonDate from "../../components/common/commonDate";
import CommonPicture from "../../components/common/commonPicture.vue";
import {
  selectOrderDistributeSettle,
  selectSettleDetail,
  orderNoticeList,
  historyPeople
} from "../../service/money.js";
import { mapState } from "vuex";

export default {
  name: "Settle",
  components: {
    Breadcrumb,
    settleDetail,
    CommonDate,
    CommonPicture
  },
  props: {},
  data() {
    return {
      Config,
      tableColumn,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "财务", isLink: false },
        { title: "结算列表", isLink: false }
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        settleFlag: 1
      },
      dialogVisible: false,
      newAddVisible: false, //新增弹框
      seeMoreForm: {
        amount: "",
        expenditureType: "",
        recordType: "",
        transactionName: "",
        transactionAccount: "",
        transactionPhone: "",
        recordTimeString: "",
        remark: "",
        noticeId: []
      },
      loading: false,
      tableData: [],
      options: [
        {
          value: "wages",
          label: "工资"
        },
        {
          value: "reimbursement",
          label: "报销"
        },
        {
          value: "supplierSettlement",
          label: "供应商结算"
        },
        {
          value: "refund",
          label: "退款"
        },
        {
          value: "marketingManagement",
          label: "市场营销"
        },
        {
          value: "fine",
          label: "罚款"
        },
        {
          value: "public relationsExpenses",
          label: "公关费"
        },
        {
          value: "rent",
          label: "房租水电成本"
        },
        {
          value: "introduction",
          label: "介绍费"
        },
        {
          value: "tax",
          label: "税费"
        },
        {
          value: "socialSecurity",
          label: "社保费"
        },
        {
          value: "development",
          label: "研发费用"
        },
        {
          value: "other",
          label: "其他"
        }
      ],
      options2: [
        {
          value: "bankCard",
          label: "银行卡"
        },
        {
          value: "alipay",
          label: "支付宝"
        },
        {
          value: "wechat",
          label: "微信"
        },
        {
          value: "cash",
          label: "现金"
        },
        {
          value: "other",
          label: "其他"
        }
      ],
      options3: [
        {
          value: "",
          label: "所有"
        },
        {
          value: 1,
          label: "待结算"
        },
        {
          value: 2,
          label: "结算中"
        },
        {
          value: 3,
          label: "已结算"
        },
        {
          value: 4,
          label: "作废"
        }
      ],
      filesList: [], //上传文件
      tableRow: "", // 客户id用于编辑/查看
      customerInfo: "", // 客户详情
      adminInfo: "", // 系统详情
      chanceFollowList: "", // 跟进list
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company,
      headerData: {}, //头部数据
      noticeList: [],
      fileList: [],
      childid: "",
      rulesIncome: {
        amount: [
          { required: true, message: "不能为空", trigger: "change" },
          {
            validator: rulePrice,
            type: "number",
            trigger: "change",
            message: "请输入数字类型"
          }
        ],
        expenditureType: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        transactionName: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        recordType: [
          { required: false, message: "不能为空", trigger: "change" }
        ],
        transactionAccount: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        transactionPhone: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        recordTimeString: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        noticeId: [{ required: true, message: "不能为空", trigger: "change" }]
      },
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState(["getUserInfo", "comId"])
  },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getComId();
    this.getNoticeList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
  //方法集合
  methods: {
    commonGetDate(startTime, endTime) {
      this.params.startDate = startTime;
      this.params.endDate = endTime;
      this.getSettleList();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.getSettleList();
    },
    commonFun() {
      this.params.pageSize = 10;
      this.params.page = 1;
      this.getSettleList();
    },
    async seeMore(ids) {
      this.loading = true;
      this.childid = ids;
      const { data } = await selectSettleDetail({ disOrderNo: ids });
      this.tableRow = data;
      this.dialogVisible = true;
      this.loading = false;
      this.$refs.settleDetail.orderWorkLog(ids);
    },
    resetBtn() {
      (this.params = {
        page: 1,
        pageSize: 10,
        settleFlag: 1,
        companyId: this.comId
      }),
      this.$refs.commonReset.resetFun();
      this.getSettleList();
    },
    // 组件关闭前
    handleClose() {
      this.getSettleList();
      this.dialogVisible = false;
    },
    async getSettleList() {
      this.loading = true;
      let params = { ...this.params };
      const { data } = await selectOrderDistributeSettle(params);
      this.pagination.total = data.total;
      this.pagination.pageSize = data.size;
      this.tableData = data.list;
      this.loading = false;
    },
    loadFun(value) {
      this.loading = value;
    },
    close() {
      this.$refs.form.resetFields();
      this.newAddVisible = false;
    },
    async getNoticeList() {
      let resData = (await orderNoticeList()).data;
      this.noticeList = resData;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    async getHisHuman() {
      const { data } = await historyPeople();
      console.log(data, "获取上次出账的收款人信息");
      this.seeMoreForm.transactionName = data.transactionName;
      this.seeMoreForm.transactionPhone = data.transactionPhone;
      this.seeMoreForm.transactionAccount = data.transactionAccount;
    },
    // 获取comID&&请求列表
    getComId() {
      setTimeout(() => {
        if (this.comId) {
          this.params.companyId = this.comId;
        } else {
          this.params.companyId = this.getUserInfo.roles_company.filter(
            (item) => {
              return item.id != 0;
            }
          )[0].id;
        }
        this.getSettleList();
      }, 400);
    }
  }
};
</script>

<style lang="less" scoped>
.payout-container {
  text-align: left;
}
.con-rec {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .addFileBtn {
    height: 20px;
    color: #1890ff;
    font-size: 14px;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 8px;
    .addFileInput {
      position: absolute;
      left: 0;
      top: 0;
      width: 120px;
      height: 20px;
      opacity: 0;
    }
  }
}
.uploadimg {
  font-size: 12px;
  color: #ccc;
  margin-left: 8px;
}
/deep/.el-tag.el-tag--info {
  background-color: #1890ff !important;
  border-color: #e9e9eb !important;
  color: #ffffff !important;
}
/deep/.el-tag.el-tag--info .el-tag__close {
  border: 1px solid #fff !important;
}
/deep/.el-tag.el-tag--info .el-tag__close {
  color: #fff;
}
/deep/.el-select .el-tag__close.el-icon-close {
  background-color: transparent;
}
.orderFile-container {
  .orderFileBtn {
    width: 100px;
    height: 32px;
    line-height: 32px;
    border: 1px solid rgba(220, 223, 230, 100);
    color: #666;
    background: #fff;
    text-align: center;
    font-size: 14px;
    position: relative;
    border-radius: 4px;
    display: inline-block;
    .orderFile-input {
      cursor: pointer;
      font-size: 0;
      width: 100px;
      height: 32px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }
}
.fileList-container {
  .fileList-item {
    display: flex;
    justify-content: space-between;
    width: 320px;
    .item-name {
      font-size: 12px;
      color: #333;
    }
    .deleteBtn {
      color: #1890ff;
      cursor: pointer;
    }
  }
}
/deep/.el-table .cell {
  white-space: nowrap;
}
.mar {
  margin-left: 0;
}
.deleteflag1 { //dai结算
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  padding: 4px 6px;
  border-radius: 4px;
  border: 1px solid rgba(255, 77, 79, 0.5);
  color: #FE4C4E;
}
.deleteflag2 { //yi结算
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  padding: 4px 6px;
  border-radius: 4px;
  border: 1px solid rgba(24, 144, 255, 50);
  color: #1890ff;
}
.deleteflag3 { //结算中
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  padding: 4px 6px;
  border-radius: 4px;
  border: 1px solid #66cc1f;
  color: #66cc1f;
}
.deleteflag4 {
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  padding: 4px 6px;
  border-radius: 4px;
  border: 1px solid #b0b0b0;
  color: #b0b0b0;
}
.del {
  color: #ccc;
  text-decoration: line-through;
}
.no-width{
  width: 17%;
}
</style>
